import React, { useState } from 'react';
import { Link } from 'react-scroll';
import Modal from '../Modal/Modal';

import './Footer.scss';

import tel from '../../assets/tel.svg';
import logo from '../../assets/logo.png';

const Footer = () => {

    const [modalState, setModalState] = useState(false);    

  return (
    <div className='footer'>
        <div className='telBlock'>
            <div className='phoneNum'>
                <img src={tel} alt="" />
                <p className='tel'>
                    +380-99-240-2750
                </p>
            </div>
            <div className='phoneNum'>
                <img src={tel} alt="" />
                <p className='tel'>
                    +380-50-643-4344
                </p>
            </div>
            <div className='btnBlock'>
                <button className='btn'  onClick={() => setModalState(true)}>
                    Leave your message
                </button>
            </div>
        </div>
       <div className='list'>
        <ul> 
            <li><Link to='program' smooth={true} offset={-150} duration={500} >Who are we?</Link></li>
            <li><Link to='part' smooth={true} offset={-150} duration={500} >Our partners</Link></li>
            <li ><Link to='services' smooth={true} offset={-150} duration={500} >Our services</Link></li>
            <li><Link to='testimonials' smooth={true} offset={-260} duration={500}  >Latest news</Link></li>
            <li><Link to='testimonials' smooth={true} offset={-260} duration={500}>Contacts</Link></li>
        </ul>
      
       </div>
       <div className='logoBlock'>  
            <img src={logo} alt="" />
            <p>
                Copyright © 2024 International Innovation and Creative Industries cluster
            </p>
       </div>
       <Modal call={modalState} onDestroy={() => setModalState(false)}/>
    </div>
  )
}

export default Footer;