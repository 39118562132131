import React, { useEffect, useState } from 'react';
import { Link as LinkScroll } from 'react-scroll'
import { Link as LinkRouter} from 'react-router-dom';
import { FiMenu, FiX } from "react-icons/fi";


 

import './Navbar.scss';

// import { PiGitlabLogoFill } from "react-icons/pi";
import logo from '../../assets/logo.png';
import Modal from '../Modal/Modal';

const Navbar = () => {

  const [sticky, setSticky] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 100 ? setSticky(true) : setSticky(false);
    })
  },[])

  const toggleMenu = () => {
    mobileMenu ? setMobileMenu(false) : setMobileMenu(true);
  }

  const onclickCategory = (index) => {
    setActiveIndex(index)
  }

  return (
    <nav className={`container ${sticky ? 'dark-nav' : ''}`}>
        <div className='logo'>
         <LinkRouter to='/'><img src={logo}/></LinkRouter>
          </div>
        <ul className={mobileMenu ? '' : 'hide-mobile-menu'}>
            <a className='linkA' href='/'><li className={activeIndex === 0 ? 'active' : ''}><LinkScroll to='program' smooth={true} offset={-150} duration={500}  onClick={() => onclickCategory(0)}>Who are we?</LinkScroll></li></a>
            <li className={activeIndex === 1 ? 'active' : ''}><LinkScroll to='part' smooth={true} offset={-150} duration={500}  onClick={() => onclickCategory(1)}>Our partners</LinkScroll></li>
            <li className={activeIndex === 2 ? 'active' : ''}><LinkScroll to='services' smooth={true} offset={-150} duration={500}  onClick={() => onclickCategory(2)}>Our services</LinkScroll></li>
            <li className={activeIndex === 3 ? 'active' : ''}>
              <LinkRouter to="/news" onClick={() => onclickCategory(3)}>Latest news</LinkRouter>
            </li>
            <li className={activeIndex === 4 ? 'active' : ''}><LinkScroll to='footer' smooth={true} offset={-150} duration={500}  onClick={() => onclickCategory(4)}>Contacts</LinkScroll></li>
            <li className={activeIndex === 5 ? 'active' : ''}><LinkScroll to='contact' smooth={true} offset={-260} duration={500} className='btn' onClick={() => setModalState(true)} >Leave your message</LinkScroll></li>
        </ul>
        <p className='menu-icon' onClick={toggleMenu} >
        {mobileMenu ? <FiX /> : <FiMenu />}
        </p>
        <Modal call={modalState} onDestroy={() => setModalState(false)}/>
    </nav> 
  )
}

export default Navbar; 