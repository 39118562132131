import React from 'react';

import './Cluster.scss';

import rec1 from '../../assets/rectangle1.png';
import rec2 from '../../assets/rectangle2.png';
import rec3 from '../../assets/rectangle3.png';
import cltr from '../../assets/clustertree.png';

const Cluster = () => {
    return (
        <div className='clusterContainer'>
            <div className='cluster'>
                <div className='left'>
                    <img src={rec1} alt="" />
                    <p className='leftText'>
                        Become our member
                    </p>
                </div>
                <div className='center'>
                    <img src={rec2} alt="" />
                    <p className='centerText'>
                        1 year for free
                    </p>
                </div>
                <div className='right'>
                    <img src={rec3} alt="" />
                    <p className='rightText'>
                        Join our activities
                    </p>
                </div>
            </div>
            <div className='link'>
                <h3>
                    Fill out the form <a href="https://docs.google.com/forms/d/e/1FAIpQLSdmV5pE7M7KYsxvEPHdt9xHGZgodzY4s3neDfVa4sfgKczd_Q/viewform" target='_blank'>by the link</a> to become a member of International ICI Cluster.
                </h3>
                <p>
                    Our membership is Free of Charge till the end of 2024. <br/>
                    Some of our activities and training programs will need additional payments to become <br/>
                    participants of the program. Additional information about annual member allocations <br/>
                    you will get by the 12th of December, 2024.
                </p>
                <img src={cltr} alt="" />
            </div>
        </div>

    )
}

export default Cluster;