import React from 'react';

import './Services.scss';

import serv1 from '../../assets/serv1.png';
import serv2 from '../../assets/serv2.png';
import serv3 from '../../assets/serv3.png';
import serv4 from '../../assets/serv4.png';

const Services = () => {
  return (
    <div className='services'>
        <div className='servicesBlock'>
            <div className='item'>
                <div className='imgBlock'>
                    <img src={serv1} alt="" />
                    <h4>
                        Market research
                    </h4>
                </div>
                <div  className='textBlock'>
                    <h5>
                        Willing to find a new market to distribute your products?
                    </h5>
                    <p>
                        International market research is a key element of successful export planning.
                    </p>
                    <p>
                        We can help you! We offer you professional research to find your potential market. 
                    </p>
                    <p>
                        Start with the first steps in exporting. We will provide you with a personalised Map and <br/>
                        plan for entering new markets.
                    </p>
                </div>
            </div>
            <div className='item'>
                <div className='imgBlock'>
                    <img src={serv2} alt="" />
                    <h4>
                        Partners search
                    </h4>
                </div>
                <div  className='textBlock'>
                    <h5>
                        Find your reliable clients and partners with our support.
                    </h5>
                    <p>
                        We are providing as the individual consultations the same time you will get free of charge support and <br/> mentoring of  how to find partners with the most popular IT mechanisms such as Statista, ITC  Trade <br/> Helpdesk, European Enterprise Europe and  others.
                    </p>
                </div>
            </div>
            <div className='item'>
                <div className='imgBlock'>
                    <img src={serv3} alt="" />
                    <h4>
                        Capacity building
                    </h4>
                </div>
                <div className='textBlock'>
                    <h5>
                        Organisation of personal and group B2B and B2C meetings.
                    </h5>
                    <p>
                        We will support you during the presentation at the biggest trade shows and fashion shows.
                    </p>
                    <p>
                        For fashion and textile companies: Personal support for design companies in press service and showroom <br/> visits during the world's most famous trade and fashion shows.
                        Portfolio preparation and collection<br/> support.
                        Individual preparation of a trade campaign.
                    </p>
                    <p>
                        For startups and innovative companies: Providing support during participation in exhibitions and <br/> personal meetings with the VCs. Support in startup assessment and business plan analysis.
                    </p>
                </div>
            </div>
            <div className='item'>
                    <div className='imgBlock'>
                        <img src={serv4} alt="" />
                        <h4>
                            Training services 
                        </h4>
                    </div>
                    <div  className='textBlock'>
                        <h5>
                            Organisation of various business trainings and seminars
                        </h5>
                        <p>
                            With our partners, we conduct various trainings and seminars that will support your business <br/> and innovative activities.
                        </p>
                        <p>
                            Trainings are held all over the world, and a team of experts offers various corporate trainings for IT <br/> specialists, marketing and export experts.
                        </p>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default Services;














