import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Title from '../Title/Title';

import './News.scss';

const News = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        fetch('https://icicluster.com/api/news/') 
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data); 
                setNews(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <div className="news">
            <Navbar/>
            <div className='newsContainer'>
                <Title title='Latest News'/>
                    {news.length === 0 && <p>No news available.</p>}
                    {news.map(item => (
                        <div key={item.id} className="newsItem">
                             {item.image && <img src={item.image} alt={item.title} />}
                            <h2>{item.title}</h2>
                            <p className='date'><small>{new Date(item.created_at).toLocaleDateString()}</small></p>
                            <p className='text'>{item.text}</p>
                            <div className='bntBlock'>
                                <Link to={`/news/${item.id}`}>
                                    <button>Read more</button>
                                </Link>
                            </div>
                        </div>
                    ))}
               <Footer/>
            </div>
        </div>
    );
};

export default News;
