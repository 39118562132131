import React, { useEffect, useState } from 'react';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Programs from './components/Programs/Programs';
import Title from './components/Title/Title';
import About from './components/About/About';
import Testimonials from './components/Testimonials/Testimonials';
import Footer from './components/Footer/Footer';

import { Link } from 'react-scroll';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Develop from './components/Search/Develop';
import Partners from './components/Partners/Partners';
import Services from './components/Services/Services';
import Cluster from './components/Cluster/Cluster';
import News from './components/News/News';

function App() {

  // const scrollTop = () => {
  //   window.scrollTo(0,0)
  // }

  return (
    <>
      <div className="App">
        <div className='navbar__con'>
          <Navbar />
        </div>
       
        <Hero />
        <div className='container'>
         
         <Programs />
         <Title subtitle='We are the creative network uniting individual and collective businesses to create unique area for fast internationalisation and development.' title='' />
         <Testimonials />
         <Title subtitle='We are experienced in supporting business' title='Who are we?' />
         <About />
         <Title title='Our partners'/>
         <Partners/>
         <Title subtitle='We are open to those who are ready to collaborate and develop in the innovation and craft sector.' title='Who is our member?' />
         <Develop/>
         <Title title='Our services'/>
         <Services/>
         <Title title='Become a part of our Creative Cluster'/>
         <Cluster/>
         <Footer/>
        </div>       
      </div>
    </>

  );
}

export default App;

