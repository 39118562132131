import React from 'react';

import './Modal.scss';


const Modal = ({call, onDestroy}) => {
    
    if(!call) {
        return null;
    }

    const closeWnd = (e) => {
        if(e.target.className === 'modal') {
            onDestroy();
        }
    }

  return (
    <div onClick={closeWnd} className='modal'>   {/*onClick={onDestroy}          це те саме що і функція closeWnd()*/}
        <div className='modal-content'>           {/*onClick={(e) => e.stopPropagation()}*/}
            <i onClick={onDestroy} className='close'>X</i>
            <form>
            <label htmlFor="POST-name" >
                        First name
                    </label>
                    <input id="POST-name" type="text" name="name" required/>
                    <label htmlFor="POST-name" >
                        Last name
                    </label>
                    <input id="POST-surname" type='text' name='surname'required/>
                    <label htmlFor="POST-name" >
                        Email
                    </label>
                    <input id="POST-email" type='email' name='email' required/>
                    <label htmlFor="POST-name" >
                        Your message
                    </label>
                    <textarea id="POST-topic" name='topic'  required></textarea>
                    <div className='btns'>
                            <button className='btn'>Send</button>
                    </div>
            </form>
        </div>
</div>
  )
}

export default Modal;