import React from 'react';
import './Hero.scss'; 
import heroImage from '../../assets/team.jpg';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>International <br/>Creative & Innovation Industries Cluster</h1>
        <button className="btn"><a href='https://docs.google.com/forms/d/e/1FAIpQLSdmV5pE7M7KYsxvEPHdt9xHGZgodzY4s3neDfVa4sfgKczd_Q/viewform' target="_blank">Become our member</a></button>
      </div>
    </section>
  );
}

export default Hero;