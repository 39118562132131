import React from 'react';

import './Develop.scss';

const Develop = () => {
  return (
    <div className='dev'>
        <div className='upDown'>
            <div className='up'>
                <p>Deep tech</p>
                <p>IT</p>
                <p>Industries</p>
                <p>Automation</p>
                <p>Manuficturing</p>
                <p>Agriculture</p>
            </div>
            <div className='down'>
                <p>Raw materials</p>
                <p>Textile sector</p>
                <p>Art/Design</p>
                <p>Fashion design</p>
            </div>
        </div>
    </div>
  )
}

export default Develop;