import React from 'react';

import './Testimonials.scss';

import tree1 from '../../assets/tree1.png';
import tree2 from '../../assets/tree2.png';
import tree3 from '../../assets/tree3.png';
import weUn from '../../assets/2.jpg';
import prov from '../../assets/3.jpg';
import create from '../../assets/Pic.png';

const Testimonials = () => {


    return (
        <div className='testimonials'>
            <div className='unite'>
                <div className='treeText'>
                    <div className='treeBlock'>
                        <img src={tree1} alt="#" />
                    </div>
                    <div className='text'>
                        <h2>
                            We unite and connect
                        </h2>
                        <p>
                            <span>Ukrainian and international start-ups,</span>
                            <span>innovation companies, corporates, SMEs,</span>
                            <span>BSOs to support economic growth, market</span>
                            <span>internationalisation, globalisation and</span>
                            <span>networking for fast growth.</span>
                        </p>
                    </div>

                </div>
                <div className='weUn'>
                    <img src={weUn} alt="" />
                </div>
            </div>
            <div className='provide'>
                <div className='weUn'>
                    <img src={prov} alt="" />
                </div>
                <div className='treeText'>
                    <div className='treeBlock2'>
                        <img src={tree2} alt="#" />
                    </div>
                    <div className='text'>
                        <h2>
                            We provide
                        </h2>
                        <p>
                            Companies with large network trainings, mentoring and service support for business growth and development
                        </p>
                    </div>

                </div>

            </div>
            <div className='create'>
                <div className='treeText'>
                    <div className='treeBlock3'>
                        <img src={tree3} alt="#" />
                    </div>
                    <div className='text'>
                        <h2>
                            We unite and connect
                        </h2>
                        <p>
                            <span>Ukrainian and international start-ups,</span>
                            <span>innovation companies, corporates, SMEs,</span>
                            <span>BSOs to support economic growth, market</span>
                            <span>internationalisation, globalisation and</span>
                            <span>networking for fast growth.</span> 
                        </p>
                    </div>

                </div>
                <div className='weUn'>
                    <img src={create} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Testimonials;