import React from 'react';

import './Programs.scss';

import unit1 from '../../assets/Unite1.png';
import unit2 from '../../assets/Unite2.png';
import unit3 from '../../assets/Unite3.png';
import unit4 from '../../assets/Unite4.png';

const Programs = () => {
  return (
    <div className='programs'>
      <div className="program">
        <p>Unite</p>
        <img src={unit1} alt="program_1" />
      </div>
      <div className="program">
        <p>Develop</p>
        <img src={unit2} alt="program_2" />
      </div>
      <div className="program">
        <p>Internationalize</p>
        <img src={unit3} alt="program_3" />
      </div>
      <div className="program">
        <p>We come to GLOBE</p>
        <img src={unit4} alt="program_4" />
      </div>
    </div>
  );
}

export default Programs;
