import React from 'react';

import './Partners.scss';

import part1 from '../../assets/part1.svg';
import part2 from '../../assets/part2.png';
import part3 from '../../assets/part4.svg';
import part4 from '../../assets/part3.png';
import part5 from '../../assets/part.png';
import part6 from '../../assets/part6.png';


const Partners = () => {
  return (
    <div className='part'>
        <div className='partBlock'>
            <img src={part5} alt="" />
            <img src={part2} alt="" />
            <img src={part3} alt="" />
            <img src={part4} alt="" />
            <img src={part1} alt="" />
            <img src={part6} alt="" />
        </div>
        
    </div>
  )
}

export default Partners;