import React from 'react';

import './About.scss';

import trian1 from '../../assets/triangles1.png';
import trian2 from '../../assets/triangles2.png';


const About = () => {
  return (
    <div className='about'>
            <div className='tr1'>
                <img src={trian1} alt="" />
            </div>
            <div className='tr2'>
                <img src={trian2} alt="" />
            </div>
            <div className='list'>
                <ul>
                    <li>
                        more than 15 years in business support;
                    </li>
                    <li>
                        companies’ owners;
                    </li>
                    <li>
                        more than 40 projects for business development were launched;
                    </li>
                    <li>
                        we are working with the startups;
                    </li>
                    <li>
                        we are accessible to the Enterprise Europe Network (EEN), <br/>Erasmus for Young Entrepreneurs,   Cluster collaboration programmes;
                    </li>
                    <li>
                        we are working with DipTech companies, IT and Cyber security companies,<br/>young entrepreneurs, startups and modern designers, creative industry companies.
                    </li>
                </ul>
            </div>
           
          
    </div>
  )
}

export default About;





