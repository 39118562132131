import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

import './Separate.scss';

const Separate = () => {
    const { id } = useParams();
    const [newsItem, setNewsItem] = useState("");


    useEffect(() => {
        fetch(`https://icicluster.com/api/news/${id}/`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setNewsItem(data);
                console.log(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);

            });
    }, [id]);
    if (!newsItem) {
        return <div>Loading...</div>;
    }

    return (
        <div className='newsStory'>
            <Navbar/>
            <div className="newsDetail">
                <h1>{newsItem.title}</h1>
                <p className='date'><small>{new Date(newsItem.created_at).toLocaleDateString()}</small></p>
                <p className='text'>{newsItem.text}</p>
                {newsItem.image && <img src={newsItem.image} alt={newsItem.title} />}
            </div>
            <div className='footer'>
                <Footer/>
            </div>
        </div>

    );
};

export default Separate;
